import React from 'react'
import Layout from 'src/components/layout'
import Helmet from 'react-helmet'
import Banner from 'src/components/Banners/SubBanner'

import './style.scss';

export const frontmatter = {
    path: "/tools/page-seo-scanner/",
    menuLabel: "Page SEO Scanner",
}

export default class PageSEOScannerPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "",
      resultsClass: ""
    }
  }

  validateUrl = (value) => {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
  }

  validUrl = (url) => {
    if (url === "") return [false, "URL is blank"];
    if (!this.validateUrl(url)) return [false, "URL is invalid"];
    return [true, "Valid URL"];
  }

  processScan = () => {
    const validUrl = this.validUrl(this.state.url);
    if (validUrl[0]) {
      this.setState({
        resultsClass: 'loading'
      })
      fetch("/tool-functions/seo-report/", {
        method: "POST",
        mode: "no-cors",
        headers: {
          'Content-Type': 'application/json'              
        },
        body: JSON.stringify({
            url: this.state.url,
        })
      })
      .then(result => result.json())
      .then(json => {
        console.log(json)
      });
    } else {
      console.log(validUrl[1]);
    }
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>SEO Check - Page scanner</title>
        </Helmet>
        <Banner title="Page SEO Scanner" eyebrow={{link:'/tools/',text:'Tools'}} />
        <section>
          <div className="wrap">
            <div className="content">
              <p>Use this tool to get a quick snapshot of the elements on a page that should concern you from an SEO perspective.</p>
              <p>This will scan a page for elements in the head of the page and check all images to ensure all data required to be successful with SEO are present.</p>
              <p>Enter the URL of the page you'd like scanned and the results will be displayed below once obtained:</p>
              <div id="scan-field">
                <input type="text" id="scan-url" placeholder="Enter URL here..." onChange={e => this.state.url = e.target.value} />
                <button onClick={this.processScan}>Scan</button>
              </div>
            </div>
          </div>
        </section>
        <section id="scan-results" className={this.state.resultsClass}>
          <div className="wrap">
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
          </div>
        </section>
      </Layout>
    )
  }
}