import React from 'react'
import { StaticQuery, graphql } from "gatsby"
//import Logo from '../../elements/Logo'

import "./style.scss"

export default props => (
    <StaticQuery
      query={graphql`
        query {
            allClientsJson(filter:{displayTestimonial:{eq: true}}) {
                edges {
                  node {
                    clientKey
                    businessName
                    logo
                    url
                    testimonial
                    author
                  }
                }
            }
        }
      `
      }
      render = {data => {
          if (data.allClientsJson !== null) {
            return (
                <section id="testimonial" className={`hero ${props.classes || null}`}>
                    <div className="wrap">
                        <h2>{props.headline || "Don't take our word for it."}</h2>
                        {data.allClientsJson.edges[0].node.testimonial}
                        {data.allClientsJson.edges[0].node.author}
                    </div>
                </section>
            )
          } else {
            return false
          }
      }}
    />
)