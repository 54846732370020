import React from 'react'

import Layout from 'src/components/layout'

import Helmet from 'react-helmet'

//import Banner from 'src/components/Banners/HomeBanner'

const ToolsPage = () => (
  <Layout>
    <Helmet>
      <title>Useful tools</title>
    </Helmet>
    <section>
      Tools page
    </section>
  </Layout>
)

export default ToolsPage
