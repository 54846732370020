import React from 'react'

import "./style.scss"

const AboutSummary = (props) => (
    <>
        <section id="about" className={`summary ${props.classes || null}`}>
            <div className="wrap">
                <h2>{props.headline || "Know who you are working with."}</h2>
                
            </div>
        </section>
    </>
)

export default AboutSummary