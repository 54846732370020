import React from 'react'
import { graphql, Link } from "gatsby"
import Helmet from 'react-helmet'
import Layout from 'src/components/layout'
import Banner from 'src/components/Banners/SubBanner'

import './style.scss';

const Highlights = ({list}) => {
  const highlights = list.map((hl, i) => {
    const info = hl.node.frontmatter
    const bg = require("src/"+info.highlightBg);
    return (
      <div key={i} className="highlightRow" style={{backgroundImage: 'url('+bg+')', color: info.highlightColor}}>
        <Link to={info.path}></Link>
        <span>{info.blurb}</span>
      </div>
    )
  })
  return highlights;
}

export default function HowWeHelpPage({
  data, // this prop will be injected by the GraphQL query below.
}) {
  return (
    <Layout>
    <Helmet>
      <title>How we can help you</title>
    </Helmet>
    <Banner 
      title="How we can help you" 
      subtitle="See how we integrate into our current clients and help them achieve success. Are you our next client highlight?"
    />
    <section>
      <div className="wrap">
        <Highlights list={data.howWeHelp.edges} />
      </div>
    </section>
  </Layout>
  )
}

export const pageQuery = graphql`
  {
    howWeHelp: allMarkdownRemark(
      filter:{
        frontmatter:{path:{regex: "/how-we-help/"}}
      }
    ) {
        edges {
          node {
            frontmatter {
              path
              headline
              highlightBg
              highlightColor
              blurb
            }
          }
        }
      
    }
  }
`