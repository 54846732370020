import React from 'react'
import { StaticQuery, graphql } from "gatsby"
import Logo from '../../elements/Logo'

import "./style.scss"

const Logos = ({data}) => {
    const Logos = data.allClientsJson.edges.map((client) => {
        const logoPath = require("../../"+client.node.logo);
        const insert = (logoPath, client) => {
            return <img src={logoPath} alt={`${client.node.businessName} logo`} title={client.node.businessName} />
        }
        return <Logo key={client.node.clientKey} link={client.node.url} name={insert(logoPath, client)} />
    })  

    return (
        <div className="logos">
            {Logos}
        </div>
    )
} 

export default props => (
    <StaticQuery
      query={graphql`
        query {
            allClientsJson(filter:{displayLogo:{eq: true}}) {
                edges {
                  node {
                    clientKey
                    businessName
                    logo
                    url
                  }
                }
            }
        }
      `
      }
      render = {data => (
        <section id="clients" className={`grid ${props.classes || null}`}>
            <div className="wrap">
                <h2>{props.headline || "Some of the clients that trust in the ARD Service."}</h2>
                <Logos data={data} />
            </div>
        </section>
      )}
    />
)