import React from 'react'
import {Link} from 'gatsby'

import Layout from 'src/components/layout'

import Helmet from 'react-helmet'

import Banner from 'src/components/Banners/HomeBanner'
import Services from 'src/components/Services/Grid'
import Clients from 'src/components/Clients/Grid'
import Testimonials from 'src/components/Testimonials/Hero'

import './home.scss';

const IndexPage = () => (
  <Layout pageID="home" headerTheme="light" headerClasses="float">
    <Helmet>
      <title>Website management</title>
    </Helmet>
    <Banner 
      title="Empower your Business" 
      subtitle="Let us look after the technical so you don't have to.<br />That's the ARD Service." 
    />
    <section id="develop" className="detail left">
      <div className="wrap">
        <div className="image"></div>
        <div className="content">
          <h2>Build it right the first time.</h2>
          <p>No one likes to do things more than once, including you and your projects. Having to rebuild websites or rewrite functionality for a product is an expensive and time-consuming event, potentially costing your business more than just the time to redo it.</p>
          <p>Time and time again do you hear about less than adequate jobs coming out of off-shore development houses that just churn and burn work to get to the next project, all without thinking about their client and their clients end-user. This often leads to businesses having to redo work.</p>
          <p>The "measure twice, cut once" mentality doesn't just relate to carpentry - we believe in proper planning and careful thought process to ensure the execusion of your project is concise and right - the first time.</p>
        </div>
      </div>
    </section>
    <section id="extension" className="detail right">
      <div className="wrap">
        <div className="image"></div>
        <div className="content">
          <h2>Think of us as an extension of your team.</h2>
          <p>Whether you need a complete web strategy, or some extra hands in your creative or engineering team - we can slot into your business, seamlessly, whereever you need us to assist in your web development requirements.</p>
          <p>The success of your business means the success of our clients, which is priority one at ARD Web Development. Your priorities are our priorities. Your success is our success.</p>
          <Link className="btn" to="/contact-us/">Get in contact</Link>
        </div>
      </div>
    </section>
    <Clients classes="off" />
    <Services />
    <Testimonials classes="off" />
  </Layout>
)

export default IndexPage
