import React from 'react'
import { StaticQuery, graphql, Link } from "gatsby"

import "./style.scss"

const Tile = data => {
    const icon = require("../../"+data.icon.substring(1, data.icon.length));
    return (
        <div className={`tile ${data.id}`}>            
            <span className="icon">
                <img src={icon} alt={data.id} title={data.title} />
            </span>
            <div>
                <h5>{data.title}</h5>
                <p>{data.blurb}</p>
            </div>
        </div>
    )
}

const Grid = ({data}) => {
    const Tiles = data.services.edges.map((tile) => {
        const array = tile.node.frontmatter;
        return <Tile key={array.id} {...array} />
    })  

    return (
        <div className="tiles">
            {Tiles}
        </div>
    )
} 

export default props => (
    <StaticQuery
      query={graphql`
        query {
            services: allMarkdownRemark(
                filter:{
                    frontmatter:{
                        path:{regex: "/services/"}, 
                        grid:{eq: true}
                    }
                },
                sort: {
                    fields: [frontmatter___gridOrder]
                    order: ASC
                }
                ) {
                edges {
                  node {
                    frontmatter {
                        id
                        path
                        icon
                        title
                        blurb
                    }
                  }
                }
              }
        }
      `
      }
      render = {data => (
        <section id="services" className={`grid ${props.classes || null}`}>
            <div className="wrap">
                <h2>{props.headline || "Some of the services we can provide you."}</h2>
                <Grid key="services" data={data} />
                <Link to="/services/" className="btn secondary">Discover all our services</Link>
            </div>
        </section>
      )}
    />
)