import React from 'react'
import { graphql, Link } from "gatsby"
import Helmet from 'react-helmet'
import Layout from 'src/components/layout'
import Banner from 'src/components/Banners/SubBanner'

import './style.scss';

const GroupSections = ({groups}) => {
  const groupsOutput = groups.map((group, i) => {
    const services = group.edges;
    return (
      <div key={i} className={`category ${group.fieldValue.toLowerCase()}`}>
        <h2>{group.fieldValue}</h2>
        <MenuGrid paths={services} />
      </div>
    )
  })
  return groupsOutput
}

const MenuGrid = ({paths}) => {
  const menuItems = paths.map((service, i) => {
    const info = service.node.frontmatter
    const icon = require("src/"+info.icon.substring(1, info.icon.length));
    return (
      <li key={i}>
        <Link to={info.path}></Link>
        <h5 style={{"backgroundImage":"url('"+icon+"')"}}>{info.menuLabel}</h5>
        <p>{info.blurb}</p>
      </li>
    )
  })
  return <ul>{menuItems}</ul>
}

export default function ServicesPage({
  data, // this prop will be injected by the GraphQL query below.
}) {
  return (
    <Layout>
    <Helmet>
      <title>Services we provide</title>
    </Helmet>
    <Banner 
      title="Services we can provide you." 
      subtitle="With a wide range of services, you can have peace of mind that you have every base covered."
    />
    <section>
      <div className="wrap">
        <GroupSections groups={data.services.group} />
      </div>
    </section>
  </Layout>
  )
}

export const pageQuery = graphql`
  {
    services: allMarkdownRemark(
      filter:{
        frontmatter:{path:{regex: "/services/"}}
      },
      sort: {
          fields: [frontmatter___categoryOrder]
          order: ASC
      }
    ) {
      group(field: frontmatter___category) {
        fieldValue
        totalCount
        edges {
          node {
            frontmatter {
              path
              icon
              menuLabel
              blurb
            }
          }
        }
      }
    }
  }
`