import React from 'react'
import {Link} from 'gatsby'

import './style.scss'

const Banner = ({ title, subtitle }) => (
    <>
        <section id="banner" className="home">
            <div className="center">
                <h1>{title}</h1>
                <h4 dangerouslySetInnerHTML={{__html: subtitle}}></h4>
                <Link className="btn" to="/contact-us/">Let's chat</Link>
            </div>
        </section>
    </>
)

export default Banner