import React from "react"
import Helmet from 'react-helmet'
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from 'src/components/layout'
import Banner from 'src/components/Banners/SubBanner'

import "./services.scss"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <Helmet>
        <title>{frontmatter.title}</title>
      </Helmet>
      <Banner title={frontmatter.headline} eyebrow={{link:'/services/',text:'Services'}} />
      <section id="single-service">
        <div className="wrap">
          <div className="content" dangerouslySetInnerHTML={{ __html: html }}></div>
          <div className="hero">
            <Img sizes={frontmatter.hero.childImageSharp.sizes} />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
        html
        frontmatter {
            path
            title
            headline
            hero {
              childImageSharp{
                sizes(maxWidth: 630) {
                    ...GatsbyImageSharpSizes
                }
              }
            }
        }
    }
  }
`